import { NavigationGuardNext, RouteRecordRaw } from 'vue-router';
import { RouteNames } from './route-names.enum';
import ActivityPage from '@/pages/activities/survey-activity-page.vue';
import { guidRegex } from '@/router/router.constants';
import { useAuthenticationStore } from '@/store/authentication-store';
import { selectIntakeCallback } from '@api/services/query/default/ActivityService';
import { completeScreenerActivity } from '@api/services/command/default/ActivityService';
import { SurveyOutcomeCode } from '@api/models/query';
import { GetCookieConsent } from '@/common/utils/cookie-yes';
import { COOKIEYES_SITE_ID } from '@/common/env.config';
import { useSquareStore } from '@/store/square-store';

export const activityRoutes: RouteRecordRaw[] = [
	{
		name: RouteNames.Activity,
		path: `/activity/:sampleGuid(${guidRegex})/:channel?`,
		component: ActivityPage,
		props: {
			type: 'activity',
		},
		meta: {
			requireLogin: true,
			adSquareHandle: true,
			flutterInAppHandler: 'activityClickedInWebview', // indicates that this route might be called from within a webview in the app
		},
	},
	{
		name: RouteNames.ActivityResearch,
		path: `/researchactivity/:activityGuid(${guidRegex})/:channel?`,
		component: ActivityPage,
		props: {
			type: 'researchactivity',
		},
		meta: {
			requireLogin: true,
		},
	},
	{
		name: RouteNames.ActivityTest,
		path: `/testactivity/:activityGuid(${guidRegex})`,
		component: ActivityPage,
		props: {
			type: 'test',
		},
		meta: {
			requireLogin: false,
		},
	},
	{
		name: RouteNames.ActivityProfileTest,
		path: '/testactivity',
		component: ActivityPage,
		meta: {
			requireLogin: false,
			title: '(LabelWindowTitleTestProfileActivity)',
		},
	},
	{
		name: RouteNames.ActivityIntake,
		path: `/intake/:activityGuid(${guidRegex})`,
		component: ActivityPage,
		props: {
			type: 'intake',
		},
		meta: {
			requireLogin: false,
		},
		beforeEnter: (to, _from, next) => {
			if (!COOKIEYES_SITE_ID || GetCookieConsent().consent === 'yes') {
				next();
			} else {
				next({
					name: RouteNames.LandingPage,
					query: {
						landingMessage: '(LabelCheckCookieSettingsBeforeRedirect)',
						timeout: -1,
						isCookiePage: 'true',
						redirectState: RouteNames.ActivityIntake,
						redirectParams: JSON.stringify({
							activityGuid: to.params.activityGuid,
						}),
						...to.query,
					},
				});
			}
		},
	},
	{
		name: RouteNames.IntakeFinished,
		path: `/intakefinished/:intakeGuid(${guidRegex})`,
		component: {},
		meta: {
			requireLogin: false,
		},
		beforeEnter: (to, _from, next) => {
			const intakeGuid: string = to.params.intakeGuid as string;
			if (to.query.outcomeGuid) {
				// NEW implementation. Will only work for surveys with a newer configuration
				completeScreenerActivity({
					intakeGuid,
					outcomeGuid: to.query.outcomeGuid as string,
					squareParticipantGuid: to.query.squareParticipantGuid as string,
					// deprecated
					outcomeCode: 0,
				})
					.then((callback) => {
						processIntakeFinishedCallback(
							callback as IntakeFinishedCallbackResponse,
							to.query.squareParticipantGuid?.toString() || '',
							next,
						);
					})
					.catch(() => {
						next({ name: RouteNames.LandingPage });
					});
			} else {
				// OLD implementation. Keep here for surveys with an older configuration
				selectIntakeCallback(intakeGuid)
					.then((callback) => {
						processIntakeFinishedCallback(
							callback as IntakeFinishedCallbackResponse,
							to.query.squareParticipantGuid?.toString() || '',
							next,
						);
					})
					.catch(() => {
						next({ name: RouteNames.LandingPage });
					});
			}
		},
	},
];

const processIntakeFinishedCallback = (
	callback: IntakeFinishedCallbackResponse,
	squareParticipantGuid: string,
	next: NavigationGuardNext,
) => {
	const auth = useAuthenticationStore();
	if (callback.ssoToken) {
		auth.setToken(callback.ssoToken);
		next({ name: RouteNames.Home });
		return;
	}

	// for AD squares, if qualified we go to activation and also open redirect URL in new tab, if any
	const adLogin = useSquareStore().info.adLogin;
	if (adLogin) {
		if (callback.outComeCode === SurveyOutcomeCode.Qualified) {
			if (callback.redirectUrl) {
				// we also need to open redirect URL in new tab
				window.open(callback.redirectUrl, '_blank');
			}
			next({
				name: RouteNames.ADActivation,
				query: {
					identity: callback.identity ?? squareParticipantGuid,
				},
			});
			// we are done here, because of AD square and Qualified
			return;
		}
	} else if (callback.redirectUrl) {
		// for non AD squares, if there is a redirect URL, we just use it
		window.location.href = callback.redirectUrl;
		next();
		// we are done here, because of non AD square and redirect URL
		return;
	}

	// if above conditions not met, redirect based on outcome
	const outComeCodeName = (Object.keys(SurveyOutcomeCode) as Array<keyof typeof SurveyOutcomeCode>).find(
		(key) => SurveyOutcomeCode[key] === callback.outComeCode,
	);
	next({
		name: RouteNames.LandingPage,
		query: {
			timeout: -1,
			isExternal: 'true',
			landingTitle: `LabelConfigurationStatus${outComeCodeName}CallbackPixelTitle`,
			landingMessage: `LabelConfigurationStatus${outComeCodeName}CallbackPixelMessage`,
		},
	});
};

// Both completeScreenerActivity and selectIntakeCallback return the same properties which are defined in 2 different interfaces
// We declare a common interface here to make it easier to write generic methods with them
interface IntakeFinishedCallbackResponse {
	ssoToken: string;
	redirectUrl: string;
	outComeCode: number;
	identity?: string;
}
